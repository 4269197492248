<template>
    <div class="">
        <ATable :url="tableUrl" :columns="columns" :searchFormItems="searchFormItems" ref="table">
            <el-button type="primary" @click="Editor()">新增</el-button>
        </ATable>
    </div>
</template>


<script>
import ATable from '@/components/table/index'
import { provide, ref } from 'vue'

import { list } from '@/api/goout'
export default {
    components: {ATable},
    setup () {
        const tableUrl = ref(list)
        const columns = ref([
            // {prop: 'shopId', label: 'ID', width: 100},
            {prop: 'shopName', label: '商品名称'},
            {prop: 'startTime', label: '外出开始时间', render (text) {return text.replace('T', ' ')}},
            {prop: 'endTime', label: '外出结束时间', render (text) {return text.replace('T', ' ')}},
        ])
        const searchFormItems = ref([
        ])
        let editorForm = ref({})
        let table = ref(null)
        provide('editorForm', editorForm)

        const methods = {
        }

        return {
            table,
            tableUrl,
            columns,
            searchFormItems,
            ...methods
        }
    }
}
</script>

<style lang="scss" scoped>

</style>